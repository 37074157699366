import axios from "axios"
import { ADD_ADHERANT, CLEAR_ADHERANT, CONFIRMED_SMS, CONFIRM_SMS, DELETE_ADHERANT, EDIT_ADHERANT, FAIL_ADHERANT, FIND_ADHERANT,  GET_ADHERANTCEDRA, GET_ADHERANTORGE, GET_ADHERANTSMSCEDRA, GET_ADHERANTSMSORGE, GET_ADH_SUPP, GET_STATISTICADH, LOAD_ADHERANT, RESET_ADHERANT_CEDRA, RESET_ADHERANT_ORGE, RESET_CONFIRM_LIST } from "../ActionType/actionTypes"
import { toast } from "react-toastify"





export const addAdherant =(adherant)=>async(dispatch) =>{
    dispatch({type:LOAD_ADHERANT})
    try { 
       
        if(adherant.name && adherant.lastname && adherant.fathername && adherant.phone && adherant.dependency && adherant.proxy){
        let result = await axios.post('/api/Adherant/addADH', adherant)
        dispatch({type:ADD_ADHERANT, payload : result.data})}
        else{
            toast("يرجى تعمير كل البيانات")
        }
    } catch (error) {
        dispatch({type: FAIL_ADHERANT, payload: error.response})
    }
    };

    /*Edit Adherant */
export const editAdherant = (_id,adherant)=>async(dispatch)=> {
    dispatch({type: LOAD_ADHERANT})
    try {
        if(adherant){
            let result = await axios.put(
                `/api/Adherant/editADH/${_id}`,
                adherant
              );
        
              dispatch({ type: EDIT_ADHERANT, payload: result.data });
            } else {
              toast("يجب تعديل المعلومات");
            }
          } catch (error) {
            dispatch({ type: FAIL_ADHERANT, payload: error.response });
          }
        };
    /* Find Adherant */

export const findAdherant = (cin)=>async(dispatch)=> {
    dispatch({type: LOAD_ADHERANT})
    try {
        if(cin){
            let result = await axios.post("/api/Adherant/findADH",cin);
        
              dispatch({ type: FIND_ADHERANT, payload: result.data });
            } 
          } catch (error) {
            dispatch({ type: FAIL_ADHERANT, payload: error.response });
          }
        };

export const deleteAdherant =(_id)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.delete(`/api/Adherant/deleteADH/${_id}`)
    dispatch({type:DELETE_ADHERANT,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}
export const clearAdherant =()=>async(dispatch)=>{
  dispatch({type:CLEAR_ADHERANT})
};

export const getadhorge=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getadhorge`,proxy)
    dispatch({type:GET_ADHERANTORGE,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const getadhcedra=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getadhcedra`,proxy)
    dispatch({type:GET_ADHERANTCEDRA,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const getadhSMSorge=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getadhSMSorge`,proxy)
    dispatch({type:GET_ADHERANTSMSORGE,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}


export const getadhSMScedra=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getadhSMScedra`,proxy)
    dispatch({type:GET_ADHERANTSMSCEDRA,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}


export const getStatisticADH=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getStatisticAdh`,proxy)
    dispatch({type:GET_STATISTICADH,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const confirmSms=(_id,newADH)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.put(`/api/Adherant/confirmSMS/${_id}`,newADH)
    dispatch({type:CONFIRM_SMS,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const resetAdherantOrge=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/resetAdherantOrge`,proxy)
    dispatch({type:RESET_ADHERANT_ORGE,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const resetAdherantCedra=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/resetAdherantCedra`,proxy)
    dispatch({type:RESET_ADHERANT_CEDRA,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}
export const confirmedSMS=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    
    let result = await axios.post(`/api/Adherant/confirmedSMS`,proxy)
    dispatch({type:CONFIRMED_SMS,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}

export const getADHApproSupp=(proxy)=>async(dispatch)=>{
  dispatch({type:LOAD_ADHERANT})
  try {
    let result = await axios.post(`/api/Adherant/getADHAppro`,proxy)
    dispatch({type:GET_ADH_SUPP,payload:result.data})
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}
export const resetConfirmListAdh=()=>async(dispatch)=>{
  try {
    dispatch({
      type:RESET_CONFIRM_LIST
    })
  } catch (error) {
    dispatch({ type: FAIL_ADHERANT, payload: error.response });
  }
}