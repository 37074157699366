import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const ModalApproStat = (props) => {
  const ADHList = useSelector(state => state.adherantReducer.StatisticADH);

  const [show, setShow] = useState(false);
  const [listAdh, setListAdh] = useState(ADHList);
  const [searchTerm, setSearchTerm] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [restO, setRestO] = useState(0);
  const [qtO, setQtO] = useState(0);
  const [totalRestCamionO, setTotalRestCamionO] = useState(0);
  const [restC, setRestC] = useState(0);
  const [qtC, setQtC] = useState(0);
  const [totalRestCamionC, setTotalRestCamionC] = useState(0);
  const [supp, setSupp] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleSearch = () => {
    if (searchTerm) {
      setListAdh(listAdh.filter(e => e.cin == searchTerm));
    } else {
      setListAdh(ADHList);
    }
  };

  const affichageDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleMonthChange = (e) => setSelectedMonth(e.target.value);
  const handleTypeChange = (e) => setSelectedType(e.target.value);

  useEffect(() => {
    const filteredHistorique = props?.el?.historique?.filter(el => {
      if (selectedMonth === '' && selectedType === '') return true;
      const dateObj = new Date(el.date);
      const month = dateObj.getUTCMonth() + 1;
      const typeMatch = selectedType === '' || el.type === selectedType;
      return (selectedMonth === '' || month === parseInt(selectedMonth)) && typeMatch;
    });

    const total = filteredHistorique?.reduce((acc, el) => acc + parseFloat(el.qt), 0) || 0;
    setTotalQuantity(total);
  }, [selectedMonth, selectedType, props?.el?.historique, supp]);

  const filteredHistorique = props?.el?.historique?.filter(el => {
    if (selectedMonth === '' && selectedType === '') return true;
    const dateObj = new Date(el.date);
    const month = dateObj.getUTCMonth() + 1;
    const typeMatch = selectedType === '' || el.type === selectedType;
    return (selectedMonth === '' || month === parseInt(selectedMonth)) && typeMatch;
  });
  
  useEffect(() => {
    if (props.el) {
      setRestO(props.el.restO || 0);
      setQtO(props.el.qtO || 0);
      setTotalRestCamionO(props.el.restDistributionOrge || 0);
    }
  }, [props.el]);

  useEffect(() => {
    if (props.el) {
      setRestC(props.el.restC || 0);
      setQtC(props.el.qtC || 0);
      setTotalRestCamionC(props.el.restDistributionCedra || 0);
    }
  }, [props.el]);

  return (
    <div>
      <Button variant="info" onClick={handleShow}>
        احصائيات
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
      >
        <Modal.Header closeButton className="text-center flex-column flex-md-row align-items-center">
          <Modal.Title className="w-100 mb-3 mb-md-0">
            احصائيات النيابة
          </Modal.Title>
          
          <div className="d-flex flex-column flex-md-row w-100 justify-content-center">
            <Form.Select
              aria-label="Select Month"
              onChange={handleMonthChange}
              className="mx-md-3 mb-3 mb-md-0"
              style={{ width: '200px' }}
            >
              <option value="">اختر الشهر</option>
              <option value="1">جانفي</option>
              <option value="2">فيفري</option>
              <option value="3">مارس</option>
              <option value="4">أفريل</option>
              <option value="5">ماي</option>
              <option value="6">جوان</option>
              <option value="7">جويلية</option>
              <option value="8">أوت</option>
              <option value="9">سبتمبر</option>
              <option value="10">أكتوبر</option>
              <option value="11">نوفمبر</option>
              <option value="12">ديسمبر</option>
            </Form.Select>
            <Form.Select
              aria-label="Select Type"
              onChange={handleTypeChange}
              className="mx-md-3"
              style={{ width: '200px' }}
            >
              <option value="">اختر النوع</option>
              <option value="شعير">شعير</option>
              <option value="سداري">سداري</option>
            </Form.Select>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div>
            <Table
              striped
              bordered
              hover
              variant="dark"
              responsive="md"
              dir="rtl"
              style={{ marginTop: '10px' }}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>اسم تاجر الأعلاف</th>
                  <th>رقم الشاحنة</th>
                  <th>المادة</th>
                  <th>الكمية</th>
                  <th>النسبة المئوية للتوزيع</th>
                  <th>تاريخ بعث الأرساليات</th>
                </tr>
              </thead>
              <tbody>
                {filteredHistorique.map((el, index) => (
                  <tr key={index}>
                    <td style={{ width: '50px' }}>{index + 1}</td>
                    <td>{props.el.name}</td>
                    <td>{el.camionNumber}</td>
                    <td>{el.type}</td>
                    <td>{el.qt}</td>
                    <td>{el.pourcentage * 100 + "%"}</td>
                    <td>{affichageDate(el.date)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex flex-column align-items-center">
          <div className="text-center mb-2">عدد الأكياس الجملي في الشاحنات: {totalQuantity}</div>

          {/* Affichage des valeurs Orge */}
          <h5 className="text-center">الشعير</h5>
          <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '0.9rem', width: '100%' }}>
            <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
              الكمية الشعير المتبقية عند بعث الإرساليات: {totalRestCamionO}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
              الكمية الشعير المتبقية من التوزيع: {restO}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-info btn-sm mx-1 my-1" disabled>
              الكمية الشعير الموزعة بعد اخر تحيين: {qtO}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-primary btn-sm mx-1 my-1" disabled>
              الكمية الشعير الموزعة الاجمالية: {props.el.TotalOrgeDistribue || 0}
            </button>
          </div>

          {/* Affichage des valeurs Cedra */}
          <h5 className="text-center">السداري</h5>
          <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '0.9rem', width: '100%' }}>
            <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
              الكمية السداري المتبقية عند بعث الإرساليات: {totalRestCamionC}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-warning btn-sm mx-1 my-1" disabled>
              الكمية السداري المتبقية من التوزيع: {restC}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-info btn-sm mx-1 my-1" disabled>
              الكمية السداري الموزعة بعد اخر تحيين: {qtC}
            </button>
            <button className="btn btn-success rounded-circle small mx-1 my-1" disabled>+</button>
            <button className="btn btn-primary btn-sm mx-1 my-1" disabled>
              الكمية السداري الموزعة الاجمالية: {props.el.TotalCedraDistribue || 0}
            </button>
          </div>
        </Modal.Footer>

        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handleClose}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalApproStat;
